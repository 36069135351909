import React, { useState } from 'react';
import { NextPage, GetServerSideProps, GetServerSidePropsContext } from 'next';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { getToken } from 'next-auth/jwt';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Link from 'next/link';
import { AuthLayout as Layout } from 'components/layouts'
import { LoginForm, LoginFormSchema, type LoginFormValues } from 'components/auth/login';
import { Button, Text } from '@skip-scanner/ui'
import { motion, HTMLMotionProps } from "framer-motion";

export const getServerSideProps:GetServerSideProps = async (context: GetServerSidePropsContext) => {

  const JWT = await getToken({ req: context.req });

  if(JWT) {
    return {
      redirect: { destination: '/', permanent: false }
    }
  }

  return {
    props: {},
  }
}

const Login:NextPage = (props) => {

  const router = useRouter();

  const login = async () => {

    setButtonsEnabled(false)
    setLoginError(null)

    const signInResponse = await signIn('credentials', {
      redirect: false,
      email: getValues('email'),
      password: getValues('password')
    })
    
    if(signInResponse?.error) {
      setLoginError('Controleer gelieve uw emailadres en / of wachtwoord.')
      setButtonsEnabled(true)
      console.log(loginError)
      return
    }

    router.push('/dashboard')
    return
  }

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors: formErrors },
    setError
  } = useForm<LoginFormValues>({
    resolver: zodResolver(LoginFormSchema)
  })
  const [ loginError, setLoginError ] = useState<string | null>(null)
  const [ buttonsEnabled, setButtonsEnabled ] = useState<boolean>(true)
 
  return (
    <Layout title='Inloggen'>

      {/* Title */}
      <Text.Heading 
        textColor={'gradient-to-b'} 
        className='from-primary-darker to-primary-light md:text-heading-xl lg:text-heading-2xl xl:text-display-sm mt-[10vh]'
      >
        Inloggen bij Skip Scanner
      </Text.Heading>

      {/* Subtitle */}
      <Text.Paragraph className='mt-2'>
        Voer uw accountgegevens in of {' '}
        
        <Link href={'/auth/register'}>
          <span className="text-primary-light underline">registreer uw account</span>.
        </Link>
      </Text.Paragraph>

      {/* Form values */}
      <motion.div 
        className="flex flex-col flex-1 gap-y-8 py-[8vh]"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0 }}
      >

        {/* Default form */}
        <LoginForm register={register} />

        {/* Errors */}
        { (!isEmpty(formErrors) || loginError) &&
          <div className="space-y-2 w-4/5">
            <Text.Label textScale={'label'} className="text-red-600">
              Er ging iets fout:
            </Text.Label>

            <Text.Paragraph className="text-paragraph-sm text-neutral-600">

              {formErrors?.email && 
                <>
                  <span className="font-medium">Emailadres: {' '}</span>  
                  {formErrors.email.message} <br/>
                </>
              }

              {formErrors?.password && 
                <>
                  <span className="font-medium">Wachtwoord: {' '}</span>  
                  {formErrors.password.message} <br/>
                </>
              }

              {formErrors?.root && 
                <>
                  <span className="font-medium">Algemeen: {' '}</span>  
                  {formErrors.root.message} <br/>
                </>
              }

              {loginError &&
                <>
                  <span className="font-medium">Algemeen: {' '}</span>  
                  {loginError} <br/>
                </>
              }

            </Text.Paragraph>
          </div>
        }

      </motion.div>

      {/* Login button */}
      <div className="flex gap-x-6 items-center pb-[10vh]">
        <Button 
          btnStyle={'primary-filled'} 
          onClick={handleSubmit(login)}
          btnState={buttonsEnabled ? 'default' : 'disabled'}
        >
          Inloggen
        </Button>
        <Text.Paragraph className='text-neutral-500 text-paragraph-sm hover:underline cursor-pointer'>
          Wachtwoord vergeten?
        </Text.Paragraph>
      </div>
    
    </Layout>
  )
}

export default Login