import React from "react";
import { Text, Tooltip, PasswordInput, TextInput } from "@skip-scanner/ui";
import { z } from "zod";
import { REGEXP_PASSWORD } from '@skip-scanner/toolkit/lib/validators'
import type { 
  UseFormRegister
} from "react-hook-form";

/**
 * Represents the values to be entered in the login form.
 * @property {string} employeeID - The user's ID.
 * @property {string} domainName - The user's domain name.
 * @property {string} password - The user's password.
 */
export type LoginFormValues = {
  email: string,
  password: string
}

/**
 * Zod schema for login form validation.
 * This schema ensures that the email address and password
 * fields are properly validated with custom error messages.
 */
export const LoginFormSchema = z.object({
  email: z.string({ 
    required_error: 'moet ingevuld zijn.'
  }).email('moet een geldig emailadres zijn.'),
  password: z.string({ 
    required_error: 'moet ingevuld zijn.'
  }).regex(REGEXP_PASSWORD, 'moet min. 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal teken bevatten. Ook moet uw wachtwoord min. 8 tekens lang zijn.')
})

/**
 * Represents the props required by the LoginForm component.
 * @property {Function} `getValues` - Function to get values from the form.
 * @property {Function} `setValue` - Function to set values in the form.
 * @property {Function} `register` - Function to register an input.
 */
type LoginFormProps = {
  register: UseFormRegister<LoginFormValues>
}

/**
 * Represents the login form component.
 * This component allows a user to enter their employeeID and password to login.
 *
 * @param {LoginFormProps} props - The props for the component.
 * @returns {React.Element} The login form component.
 */
export const LoginForm:React.FC<LoginFormProps> = ({ register }) => {

  return (
    <>
      {/* Username */}
      <TextInput
        className="'w-4/5 2xl:w-1/2"
        label="Emailadres"
        placeholder="j.janssen@osgdl.nl"
        type="email"
        {...register('email', { required: true })}
      />


      {/* Password */}
      <PasswordInput 
        className="'w-4/5 2xl:w-1/2"
        label="Wachtwoord"
        placeholder="Uw wachtwoord"
        {...register('password', { required: true })}
      />

    </>
  )
}